<template>
    <header class="navbar navbar-expand-lg bd-navbar sticky-top bg-light" style="z-index: 11 !important;">
        <nav class="container-xxl bd-gutter flex-wrap flex-lg-nowrap">
            <a class="navbar-brand" href="/">
                <!--suppress SpellCheckingInspection -->
                <img class="me-2" src="../assets/img/logo-textless.png" alt="logo"
                     style="width: 40px; height: 40px; margin-top: -15px; margin-bottom: -10px">
                <span style="text-shadow: 1px 2px 2px gray;">p-l-e.ru</span>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                    <li class="nav-item">
                        <a class="nav-link" :class="(section === '') ? 'active' : ''" href="/">Главная</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="(section === 'hc') ? 'active' : ''" :href="'/hc'">ЖК</a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            :class="(section === 'object') ? 'active' : ''"
                            :href="'/object'"
                        >
                            Объявления
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="(section === 'developers') ? 'active' : ''"
                           :href="'/developers'">Застройщики</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="(section === 'news') ? 'active' : ''" :href="'/news'">Новости</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="(section === 'articles') ? 'active' : ''" :href="'/articles'">Статьи</a>
                    </li>
                </ul>
                <div class="dropdown" ref="dropdown">
                    <p class="my-auto" @click="toggleDropdown">
                        <span class="small align-text-bottom">{{ selectedCity }}</span>
                        <i class="ps-2 bi bi-geo-alt-fill fs-5"></i>
                    </p>

                    <div v-if="isDropdownVisible" class="dropdown-menu">
                        <label>
                            <input type="radio" value="Вся Россия" v-model="selectedCity" @change="saveCity"> Вся Россия
                        </label>
                        <label>
                            <input type="radio" value="Москва" v-model="selectedCity" @change="saveCity"> Москва
                        </label>
                        <label>
                            <input type="radio" value="Санкт-Петербург" v-model="selectedCity" @change="saveCity"> Санкт-Петербург
                        </label>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import {useRoute} from "vue-router";
import {ref, watch} from "vue";

export default {
    name: "navbar",
    data() {
        return {
            selectedCity: 'Вся Россия', // значение по умолчанию
            isDropdownVisible: false,
        };
    },
    setup() {
        const route = useRoute()
        const path = () => {
            return route.path.substring(1).split('/')[0].toLowerCase()
        }
        const section = ref(path())
        watch(route, () => {
            section.value = path()
        })
        return {section}
    },
    mounted() {
        // Получаем значение из localStorage при монтировании компонента
        const savedCity = localStorage.getItem('selectedCity');
        if (savedCity) {
            this.selectedCity = savedCity;
        }

        document.addEventListener('click', this.handleClickOutside);
    },
    methods: {
        saveCity() {
            // Сохраняем значение в localStorage при изменении выбора
            localStorage.setItem('selectedCity', this.selectedCity);
            this.closeDropdown();
            location.reload();
        },
        toggleDropdown() {
            this.isDropdownVisible = !this.isDropdownVisible;
        },
        closeDropdown() {
            console.log('closeDropdown');
            this.isDropdownVisible = false; // Метод для закрытия выпадающего меню
        },
        handleClickOutside(event) {
            // Проверяем, был ли клик вне выпадающего меню
            const dropdown = this.$refs.dropdown;
            if (dropdown && !dropdown.contains(event.target)) {
                this.closeDropdown(); // Закрываем выпадающее меню
            }
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    }
}
</script>

<style scoped>
    .dropdown-menu {
        border: 1px solid #ccc;
        background-color: white;
        position: absolute;
        z-index: 1000;
        width: 170px; /* Вы можете изменить ширину по вашему усмотрению */
        display: block;
    }
    .dropdown-menu label {
        display: block;
        padding: 5px 10px; /* Добавьте отступы для удобства */
    }

    p.my-auto {
        min-width: 160px;
    }

    p.my-auto:hover span {
        text-decoration: underline;
        color: blue;
    }

    p.my-auto:hover {
        cursor: pointer;
    }

    /* Для текстового выравнивания только на ПК */
    @media (min-width: 768px) {
        .dropdown {
            text-align: right; /* Устанавливаем выравнивание текста для ПК */
        }
    }
</style>