<template>
  <div class=" marketing">
    <hr class="featurette-divider">

    <home_articles_card
        v-if="articles.articles && articles.articles.length > 0"
        :honest="false"
        :articles="articles.articles[0]"
    />

    <hr class="featurette-divider">

    <home_articles_card
        v-if="articles.articles && articles.articles.length > 1"
        :honest="true"
        :articles="articles.articles[1]"
    />

    <hr class="featurette-divider">

    <home_articles_card
        v-if="articles.articles && articles.articles.length > 2"
        :honest="false"
        :articles="articles.articles[2]"
    />

    <hr class="featurette-divider">
  </div>
</template>

<script>
import home_articles_card from "@/components/pages/home/HomeArticlesCard";
import useArticles from "@/hooks/useArticles";
import {onMounted} from "vue";

export default {
  name: "home-articles",
  components: {home_articles_card},
  setup() {
    const {getHomeArticles, articles} = useArticles()

    onMounted(getHomeArticles)

    return {articles}
  }
}
</script>

<style scoped>
.featurette-divider {
  margin: 5rem 0;
}

.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
</style>