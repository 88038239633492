<template>
  <articles_search
      :search="filter.search"
      :how="filter.how"
      @newSearch="updateGetArticles"
  />
  <div v-for="item in articles.articles">
    <articles_card
        :articles="item"
        :type="'articles'"
    />
  </div>
  <pagination
      :number="5"
      :page="filter.page"
      :total="articles.total"
      @newPagination="updatePage"
  />
</template>

<script>
import useArticles from "@/hooks/useArticles";
import {onMounted, ref} from "vue";
import articles_card from "@/components/pages/articles/ArticlesCard";
import Pagination from "@/components/UI/Pagination";
import {useRoute, useRouter} from "vue-router";
import articles_search from "@/components/pages/articles/ArticlesSearch";

export default {
  name: "articles",
  components: {articles_search, Pagination, articles_card},
  setup() {
    const route = useRoute()
    const router = useRouter()

    const {getArticles, articles} = useArticles()

    onMounted(getArticles)

    const filter = ref({
      search: route.query.search ? route.query.search : "",
      how: route.query.how ? route.query.how : "",
      page: Number(route.query.page) ? Number(route.query.page) : 1
    })

    const myQuery = () => {
      return {
        search: filter.value.search,
        how: filter.value.how,
        page: filter.value.page
      }
    }

    const updatePage = async (page) => {
      filter.value.page = page
      await router.replace({
        query: myQuery()
      })
      location.reload()
    }

    const updateGetArticles = async (search, how) => {
      filter.value.search = search
      filter.value.how = how
      filter.value.page = 1
      await router.replace({
        query: myQuery()
      })
      location.reload()
    }

    return {updateGetArticles, updatePage, articles, filter}
  }
}
</script>

<style scoped>

</style>