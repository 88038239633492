<template>
    <!--suppress JSValidateTypes -->
    <hc-search
        :name="filter.name"
        :metro="filter.metro"
        :deadline="filter.deadline"
        :how="filter.how"
        :minCostApartment="filter.minCostApartment"
        :maxCostApartment="filter.maxCostApartment"
        :minCostSquareMeter="filter.minCostSquareMeter"
        :maxCostSquareMeter="filter.maxCostSquareMeter"
        :available="filter.available"
        @newSearch="updateGetHc"
    />
    <!--suppress JSUnresolvedVariable -->
    <div v-for="item in hc.housingComplexes">
        <hc_card :hc="item"/>
    </div>
    <pagination
        :number="10"
        :page="filter.page"
        :total="hc.total"
        @newPagination="updatePage"
    />
</template>

<script>
import useHc from "@/hooks/useHc";
import {onMounted, ref} from "vue";
import hc_card from "@/components/pages/hc/HcCard";
import HcSearch from "@/components/pages/hc/HcSearch";
import Pagination from "@/components/UI/Pagination";
import {useRoute, useRouter} from "vue-router";

export default {
    name: "housing-complexes",
    components: {Pagination, HcSearch, hc_card},
    setup() {
        const route = useRoute()
        const router = useRouter()

        const {getHc, hc} = useHc()

        onMounted(async () => {
            await router.replace({
                query: myQuery()
            })
            await getHc()
        })

        const filter = ref({
            name: route.query.name ? route.query.name : "",
            metro: route.query.metro ? route.query.metro : "",
            deadline: route.query.deadline ? route.query.deadline : "",
            how: route.query.how ? route.query.how : "",
            minCostApartment: route.query.min_ca ? route.query.min_ca : "",
            maxCostApartment: route.query.max_ca ? route.query.max_ca : "",
            minCostSquareMeter: route.query.min_csm ? route.query.min_csm : "",
            maxCostSquareMeter: route.query.max_csm ? route.query.max_csm : "",
            available: route.query.available ? route.query.available : "true",
            page: Number(route.query.page) ? Number(route.query.page) : 1
        })

        const myQuery = () => {
            return {
                name: filter.value.name,
                metro: filter.value.metro,
                deadline: filter.value.deadline,
                how: filter.value.how,
                min_ca: filter.value.minCostApartment,
                max_ca: filter.value.maxCostApartment,
                min_csm: filter.value.minCostSquareMeter,
                max_csm: filter.value.maxCostSquareMeter,
                available: filter.value.available,
                page: filter.value.page
            }
        }

        const updatePage = async (page) => {
            filter.value.page = page
            await router.replace({
                query: myQuery()
            })
            location.reload()
        }

        const updateGetHc = async (
            name,
            metro,
            deadline,
            how,
            minCostApartment,
            maxCostApartment,
            minCostSquareMeter,
            maxCostSquareMeter,
            available
        ) => {
            filter.value.name = name
            filter.value.metro = metro
            filter.value.deadline = deadline
            filter.value.how = how
            filter.value.minCostApartment = minCostApartment
            filter.value.maxCostApartment = maxCostApartment
            filter.value.minCostSquareMeter = minCostSquareMeter
            filter.value.maxCostSquareMeter = maxCostSquareMeter
            filter.value.available = available
            filter.value.page = 1
            await router.replace({
                query: myQuery()
            })

            location.reload()
        }

        return {updateGetHc, updatePage, hc, filter}
    }
}
</script>

<style scoped>

</style>