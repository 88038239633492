<template>
  <div v-if="housingComplexes.id" class="pb-4">
    <a class="card shadow-sm-card cursor-pointer text-decoration-none text-dark" :href="'/hc/' + housingComplexes.id">
      <div class="ratio ratio-16x9">
        <img v-if="housingComplexes.images.length > 0" :src="'data:image;base64,' + housingComplexes.images[0].image"
             class="card-img-top object-fit-cover" alt="HousingComplex">
      </div>
      <div class="card-body">
        <h6 v-if="housingComplexes.name" class="card-title text-truncate">ЖК «{{ housingComplexes.name }}»</h6>
        <p v-if="housingComplexes.description" class="small card-text text-hc-card">{{ housingComplexes.description }}</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "developers-hc-card",
  props: {
    housingComplexes: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style scoped>
.shadow-sm-card {
  transition: all .3s;
}

.shadow-sm-card:hover {
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .125) !important
}

.text-hc-card {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 576px) {
  .card-body {
    /*noinspection CssNonIntegerLengthInPixels*/
    min-height: 122.2px
  }
}

</style>