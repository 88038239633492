import {ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {serverUrl} from "@/App.vue";
import router from "@/router";
import useTokens from "@/hooks/useTokens";

export default function UseReports() {
    const route = useRoute()

    const reportLoader = ref(false)
    const reportException = ref("")
    const report = ref([])
    const reportId = ref({
        id: null,
        dateCreate: null,
        images: []
    })

    const getReports = async () => {
        reportException.value = ""
        reportLoader.value = true

        await axios.get(
            serverUrl + `reports`, {params: route.query}
        )
            .then(response => {
                report.value = response.data.reports
            })
            .catch(async e => {
                try {
                    reportException.value = e.response.data.error
                } catch {
                    reportException.value = "Exception"
                }
            })
            .finally(() => reportLoader.value = false)
    }

    const getReportsByFile = async (fileName) => {
        reportException.value = ""
        reportLoader.value = true

        await axios.get(
            serverUrl + `report/` + fileName, {params: route.query}
        )
            .then(response => {
                report.value = response.data.reports
            })
            .catch(async e => {
                try {
                    reportException.value = e.response.data.error
                } catch {
                    reportException.value = "Exception"
                }
            })
            .finally(() => reportLoader.value = false)
    }

    const getReportId = async (id) => {
        reportException.value = ""
        reportLoader.value = true
        await axios.get(
            serverUrl + `reports/` + id
        )
            .then(response => {
                reportId.value = response.data
            })
            .catch(async e => {
                try {
                    reportException.value = e.response.data.error
                } catch {
                    reportException.value = "Exception"
                }
            })
            .finally(() => reportLoader.value = false)
    }

    return {getReports, getReportId, getReportsByFile, report, reportId}
}