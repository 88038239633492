<template>
  <advertising>
    <div class="d-flex flex-lg-wrap flex-nowrap">
      <div class="col-md-3 col-sm-4 col-6 col-lg-12 mb-lg-2 me-lg-0 me-2">
        <div id="adfox_168579695698033598" class="w-100"></div>
      </div>
      <div class="col-md-3 col-sm-4 col-6 col-lg-12 mb-lg-2 mx-lg-0 mx-2">
        <div id="adfox_168579701877643081" class="w-100"></div>
      </div>
      <div class="col-md-3 col-sm-4 col-6 col-lg-12 mb-lg-2 mx-lg-0 mx-2">
        <div id="adfox_16857970636971288" class="w-100"></div>
      </div>
      <div class="col-md-3 col-sm-4 col-6 col-lg-12 mb-max ms-lg-0 ms-2">
        <div id="adfox_168579710543872370" class="w-100"></div>
      </div>
    </div>
  </advertising>
</template>

<script>
import Advertising from "@/components/advertising/Advertising";
import {onMounted} from "vue";

export default {
  name: "advertising-right",
  components: {Advertising},
  setup() {
    onMounted(() => {
      window.yaContextCb.push(() => {
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction,SpellCheckingInspection
        Ya.adfoxCode.createAdaptive({
          ownerId: 715164,
          containerId: 'adfox_168579695698033598',
          params: {
            p1: 'cyhle',
            p2: 'p'
          }
        }, ['desktop', 'tablet', 'phone'], {
          tabletWidth: 830,
          phoneWidth: 480,
          isAutoReloads: true
        })
      })
      window.yaContextCb.push(() => {
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction,SpellCheckingInspection
        Ya.adfoxCode.createAdaptive({
          ownerId: 715164,
          containerId: 'adfox_168579701877643081',
          params: {
            p1: 'cyhlg',
            p2: 'p'
          }
        }, ['desktop', 'tablet', 'phone'], {
          tabletWidth: 830,
          phoneWidth: 480,
          isAutoReloads: true
        })
      })
      window.yaContextCb.push(() => {
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction,SpellCheckingInspection
        Ya.adfoxCode.createAdaptive({
          ownerId: 715164,
          containerId: 'adfox_16857970636971288',
          params: {
            p1: 'cyhlj',
            p2: 'p'
          }
        }, ['desktop', 'tablet', 'phone'], {
          tabletWidth: 830,
          phoneWidth: 480,
          isAutoReloads: true
        })
      })
      window.yaContextCb.push(() => {
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction,SpellCheckingInspection
        Ya.adfoxCode.createAdaptive({
          ownerId: 715164,
          containerId: 'adfox_168579710543872370',
          params: {
            p1: 'cyhlk',
            p2: 'p'
          }
        }, ['desktop', 'tablet', 'phone'], {
          tabletWidth: 830,
          phoneWidth: 480,
          isAutoReloads: true
        })
      })
    })
  }
}
</script>

<style scoped>
@media (min-width: 992px) {
  .mb-max {
    margin-bottom: 4.5rem !important;
  }
}

</style>