<template>
    <div class="bg-light text-muted text-center mt-auto">
        <div class="container-xxl">
            <footer class="py-5 mt-4">
                <ul class="nav flex-column border-bottom pb-4 mb-4">
                    <li class="">E-mail редакции портала: info@prolead-estate.ru</li>
                    <li class="">E-mail по вопросам рекламы: sales@prolead-estate.ru</li>
                </ul>
                <p>© «ПРОЛИД-ЭСТЕЙТ» {{ new Date().getFullYear() }}</p>
                <ul class="nav flex-column small">
                    <li>Публикация материалов из рубрик запрещены, остальные материалы - при наличии активной dofollow ссылки на портал p-l-e.ru</li>
                    <li>Публикация любых изображений и снимков экрана возможна только при указании автора изображения и активной dofollow ссылки на портал p-l-e.ru</li>
                    <li>Размещенная на сайте информация носит справочно-информационный характер и направлена на извещение потребителей о наличии и характеристиках объектов жилой недвижимости.</li>
                </ul>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: "ple-footer"
}
</script>

<style scoped>

</style>