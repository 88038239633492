<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li :class="page === 1? 'page-item disabled' : 'page-item'">
        <a class="page-link" @click="updatePage(page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li v-for="item in listPageItems"
          :class="item === page ? 'page-item active' : item === '...' ? 'page-item disabled' : 'page-item'">
        <a class="page-link" @click="updatePage(item)">{{ item }}</a>
      </li>
      <li :class="page >= (Math.ceil(total / number)) ? 'page-item disabled' : 'page-item'">
        <a class="page-link" @click="updatePage(page + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "pagination",
  props: {
    number: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    }
  },
  watch: {
    total() {
      this.updatePageListItem()
    },
    page() {
      this.updatePageListItem()
    }
  },
  setup(props, {emit}) {
    const listPageItems = ref([])
    const updatePageListItem = () => {
      const totalPages = Math.ceil(props.total / props.number)
      if (props.page === 1) {
      } else if (props.page < 1) {
        emit('update:page', 1)
      } else if (props.page > totalPages) {
        emit('update:page', totalPages)
      }
      if (totalPages <= 1) {
        listPageItems.value = [1]
      } else if (totalPages === 2) {
        listPageItems.value = [1, 2]
      } else if (totalPages === 3) {
        listPageItems.value = [1, 2, 3]
      } else if (props.page === 1) {
        listPageItems.value = [1, 2, '...', totalPages]
      } else if (props.page === totalPages) {
        listPageItems.value = [1, '...', (totalPages - 1), totalPages]
      } else if (totalPages === 4) {
        listPageItems.value = [1, 2, 3, 4]
      } else if (props.page === 2) {
        listPageItems.value = [1, 2, 3, '...', totalPages]
      } else if (props.page === (totalPages - 1)) {
        listPageItems.value = [1, '...', (totalPages - 2), (totalPages - 1), totalPages]
      } else if (totalPages === 5) {
        listPageItems.value = [1, 2, 3, 4, 5]
      } else {
        listPageItems.value = [1, '...', (props.page - 1), props.page, (props.page + 1), '...', totalPages]
      }
    }
    onMounted(() => {
          updatePageListItem()
        }
    )

    const updatePage = (pageNumber) => {
      emit('newPagination', pageNumber)
    }

    return {updatePage, updatePageListItem, listPageItems}
  }
}
</script>

<style scoped>
.pagination {

  --bs-pagination-active-color: var(--bs-white);
  --bs-pagination-active-bg: var(--bs-secondary);
  --bs-pagination-active-border-color: var(--bs-secondary);
  --bs-pagination-color: var(--bs-secondary);
  --bs-pagination-hover-color: var(--bs-gray-dark);
}
</style>