import {ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import useTokens from "@/hooks/useTokens";
import router from "@/router";
import {serverUrl} from "@/App.vue";

export default function useArticles() {

    const route = useRoute()
    const {authHeader, logout} = useTokens()

    const articlesLoader = ref(false)
    const articlesException = ref("")
    const articles = ref([])
    const article = ref({
        title: "",
        text: "",
        image: ""
    })

    const getHomeArticles = async () => {
        articlesException.value = ""
        articlesLoader.value = true
        await axios.get(
            serverUrl + `/articles?number=3`
        )
            .then(response => {
                articles.value = response.data
            })
            .catch(async e => {
                try {
                    console.log(e);
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const getArticles = async () => {
        articlesException.value = ""
        articlesLoader.value = true
        await axios.get(
            serverUrl + `/articles`, {params: route.query}
        )
            .then(response => {
                articles.value = response.data
            })
            .catch(async e => {
                try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const getAdminArticles = async (page) => {
        articlesException.value = ""
        articlesLoader.value = true
        await axios.get(serverUrl + `/articles?number=25&page=` + page)
            .then(response => {
                articles.value = response.data
            })
            .catch(async e => {
                try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const getArticle = async (id) => {
        articlesException.value = ""
        articlesLoader.value = true
        await axios.get(
            serverUrl + `/articles/` + id
        )
            .then(response => {
                article.value = response.data
            })
            .catch(async e => {
                try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const addArticle = async () => {
        articlesException.value = ""
        articlesLoader.value = true
        const header = await authHeader()
        await axios.post(
            serverUrl + `/articles`, article.value, {headers: header}
        )
            .then(() => {
                router.push('/admin/articles')
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await logout()
                } else try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const updateArticle = async (id) => {
        articlesException.value = ""
        articlesLoader.value = true
        const header = await authHeader()
        await axios.put(
            serverUrl + `/articles/` + id, article.value, {headers: header}
        )
            .then(() => {
                router.push('/admin/articles')
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await logout()
                } else try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const deleteArticle = async (id) => {
        articlesException.value = ""
        articlesLoader.value = true
        const header = await authHeader()
        await axios.delete(
            serverUrl + `/articles/` + id, {headers: header}
        )
            .then(() => {
                router.push('/admin/articles')
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await logout()
                } else try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const getNews = async () => {
        articlesException.value = ""
        articlesLoader.value = true
        await axios.get(
            serverUrl + `/news`, {params: route.query}
        )
            .then(response => {
                articles.value = response.data
            })
            .catch(async e => {
                try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const getAdminNews = async (page) => {
        articlesException.value = ""
        articlesLoader.value = true
        await axios.get(serverUrl + `/news?number=25&page=` + page)
            .then(response => {
                articles.value = response.data
            })
            .catch(async e => {
                try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const getNew = async (id) => {
        articlesException.value = ""
        articlesLoader.value = true
        await axios.get(
            serverUrl + `/news/` + id
        )
            .then(response => {
                article.value = response.data
            })
            .catch(async e => {
                try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const addNew = async () => {
        articlesException.value = ""
        articlesLoader.value = true
        const header = await authHeader()
        await axios.post(
            serverUrl + `/news`, article.value, {headers: header}
        )
            .then(() => {
                router.push('/admin/news')
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await logout()
                } else try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const updateNew = async (id) => {
        articlesException.value = ""
        articlesLoader.value = true
        const header = await authHeader()
        await axios.put(
            serverUrl + `/news/` + id, article.value, {headers: header}
        )
            .then(() => {
                router.push('/admin/news')
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await logout()
                } else try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    const deleteNew = async (id) => {
        articlesException.value = ""
        articlesLoader.value = true
        const header = await authHeader()
        await axios.delete(
            serverUrl + `/news/` + id, {headers: header}
        )
            .then(() => {
                router.push('/admin/news')
            })
            .catch(async e => {
                if (e.response.status === 401) {
                    await logout()
                } else try {
                    articlesException.value = e.response.data.error
                } catch {
                    articlesException.value = "Exception"
                }
            })
            .finally(() => articlesLoader.value = false)
    }

    return {
        getHomeArticles,
        getArticles,
        getAdminArticles,
        getArticle,
        addArticle,
        updateArticle,
        deleteArticle,
        getNews,
        getAdminNews,
        getNew,
        addNew,
        updateNew,
        deleteNew,
        articles,
        article
    }

}
