<template>
    <h3>Выберите нужный файл:</h3>
    <div v-for="item in report">
       <a :href="'/report/' + item.fileName">{{ item.fileName }}.xml</a>
    </div>
</template>

<script>
import useReports from "@/hooks/useReports";
import {onMounted} from "vue";

export default {
    name: "ImportReports",
    setup() {
        const {getReports, report} = useReports()

        onMounted(async () => {
            await getReports()
        })

        return {getReports, report}
    }
}
</script>

<style scoped>

</style>