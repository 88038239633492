<template>
    <div class="pb-4">
        <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button bg-white text-dark" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne">
                        Подобрать жилой комплекс
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                     aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                        <form class="row">
                            <div class="col-sm-6 pb-3">
                                <input type="text" class="form-control" v-model="data.searchName" placeholder="Название ЖК" maxlength="255">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMetro" placeholder="Метро" maxlength="255">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <select class="form-select" v-model="data.searchDeadline">
                                    <option value="" selected>Срок сдачи</option>
                                    <!--                  <option value="1">Сдано</option>-->
                                    <option value="2023">2023 год</option>
                                    <option value="2024">2024 год</option>
                                    <option value="2025">2025 год</option>
                                    <!--                  <option value="3">После 2025 года</option>-->
                                </select>
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMinCostApartment" placeholder="Мин. за кв.">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMaxCostApartment" placeholder="Макс. за кв.">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMinCostSquareMeter" placeholder="Мин. за м2">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMaxCostSquareMeter" placeholder="Макс. за м2">
                            </div>
                            <div class="col">
                                <div class="form-check pb-3">
                                    <input type="checkbox" id="availableCheckbox" class="form-check-input" v-model="data.searchAvailable" />
                                    <label for="availableCheckbox" class="form-check-label">Показать только ЖК с квартирами в продаже</label>
                                </div>
                            </div>
                            <div class="pb-3">
                                <div class="card card-body border-0 rounded-0 py-0">
                                    <div class="row small">
                                        <div class="col-auto ps-0 pe-1">
                                            Сортировать:
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="default" value="" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="default">
                                                по умолчанию
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="aDate" value="DDATE" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="aDate">
                                                по дате <i class="bi bi-sort-down-alt"></i>
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="dDate" value="ADATE" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="dDate">
                                                по дате <i class="bi bi-sort-down"></i>
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="aName" value="ANAME" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="aName">
                                                по названию <i class="bi bi-sort-down-alt"></i>
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="dName" value="DNAME" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="dName">
                                                по названию <i class="bi bi-sort-down"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col"></div>
                            <div class="col-auto">
                                <button class="btn btn-secondary" type="button" @click="newSearch">Подобрать</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {reactive} from "vue";

export default {
    name: "hc-search",
    props: {
        name: {
            type: String,
            default: ""
        },
        metro: {
            type: String,
            default: ""
        },
        deadline: {
            type: String,
            default: ""
        },
        how: {
            type: String,
            default: ""
        },
        minCostApartment: {
            type: String,
            default: ""
        },
        maxCostApartment: {
            type: String,
            default: ""
        },
        minCostSquareMeter: {
            type: String,
            default: ""
        },
        maxCostSquareMeter: {
            type: String,
            default: ""
        },
        available: {
            type: Boolean,
            default: true
        }
    },
    setup(props, {emit}) {
        const data = reactive({
            searchName: props.name,
            searchMetro: props.metro,
            searchDeadline: props.deadline,
            searchHow: props.how,
            searchMinCostApartment: props.minCostApartment,
            searchMaxCostApartment: props.maxCostApartment,
            searchMinCostSquareMeter: props.minCostSquareMeter,
            searchMaxCostSquareMeter: props.maxCostSquareMeter,
            searchAvailable: props.available
        });

        function newSearch() {
            emit(
                'newSearch',
                data.searchName,
                data.searchMetro,
                data.searchDeadline,
                data.searchHow,
                data.searchMinCostApartment,
                data.searchMaxCostApartment,
                data.searchMinCostSquareMeter,
                data.searchMaxCostSquareMeter,
                data.searchAvailable
            )
        }

        return {newSearch, data}
    }
}
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon) !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill=''%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

input:checked + label {
    color: var(--bs-gray-700) !important;
    cursor: default !important;
    font-weight: 600; @change="newSearch"
}

input:hover + label {
    color: var(--bs-gray-700) !important;
}

input + label {
    color: var(--bs-gray-600) !important;
    cursor: pointer !important;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: var(--bs-gray-700);
    border-color: var(--bs-gray-700);
}
.form-check-input[type=checkbox]:indeterminate {
    background-color: var(--bs-gray-700);
    border-color: var(--bs-gray-700);
}
</style>
