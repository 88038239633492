<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">Панель администратора</li>
        </ol>
    </nav>

    <ul class="list-group list-group-flush">
        <li class="list-group-item">
            <a class="link-secondary text-decoration-none" @click="$router.push('/admin/news')">Новости</a></li>
        <li class="list-group-item">
            <a class="link-secondary text-decoration-none" @click="$router.push('/admin/articles')">Статьи</a></li>
        <li class="list-group-item">
            <a class="link-secondary text-decoration-none" @click="$router.push('/admin/objects')">Загрузка файла для объявлений</a></li>
        <li class="list-group-item">
            <a class="link-secondary text-decoration-none" @click="$router.push('/reports')">Отчеты импорта (доступно не только админу)</a></li>
        <li class="list-group-item">
            <a class="link-danger text-decoration-none" @click="logout">Выйти</a></li>
    </ul>
</template>

<script>
import useTokens from "@/hooks/useTokens";
import {onMounted} from "vue";

export default {
    name: "admin",
    setup() {
        const {checkToken, logout} = useTokens()
        onMounted(checkToken)
        return {logout}
    }
}
</script>

<style scoped>

</style>