<!--suppress JSUnresolvedVariable -->
<template>
    <div v-if="hcId.images.length > 0" id="carouselExampleFade" class="carousel slide carousel-fade pb-3">
        <div class="carousel-inner">
            <div class="carousel-item ratio ratio-16x9 active">
                <img :src="'data:image;base64,' + hcId.images[0].image" class="d-block w-100 rounded object-fit-cover"
                     :alt="hcId.name">
            </div>
            <div v-for="item in hcId.images.slice(1, hcId.images.length-1)" class="carousel-item ratio ratio-16x9">
                <img :src="'data:image;base64,' + item.image" class="d-block w-100 rounded object-fit-cover" :alt="hcId.name">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <h2 v-if="hcId.name" class="py-3">ЖК «{{ hcId.name }}»</h2>

    <div v-if="hcId.layout[0]" class="row pb-3">
        <div v-for="item in hcId.layout" class="col-12 col-md-6 col-lg-12 col-xl-6">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-columns fs-3"></i>
                </div>
                <div class="col-auto my-auto">
                    <span v-if="item.numberRooms === 0"><strong>Студии</strong></span>
                    <span v-else-if="item.numberRooms === 100"><strong>Своб. план.</strong></span>
                    <span v-else-if="item.numberRooms === 200"><strong>Многокомн.</strong></span>
                    <span v-else><strong>{{ item.numberRooms }}</strong>-к/кв</span>
                    от <strong>{{ item.minimumArea }}</strong> м<sup>2</sup>
                    от <strong>{{ item.minimumCost / 1000000 }}</strong> млн. ₽
                </div>
            </div>
        </div>
    </div>

    <div v-if="hcId.minCostApartment || hcId.maxCostApartment || hcId.minCostSquareMeter || hcId.maxCostSquareMeter"
         class="row pb-3">
        <div v-if="hcId.minCostApartment" class="col-12 col-md-6 col-lg-12 col-xl-6">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-wallet2 fs-3"></i>
                </div>
                <div class="col-auto my-auto">
                    от <strong>{{ hcId.minCostApartment.toLocaleString() }}</strong> ₽/квартира
                </div>
            </div>
        </div>
        <div v-if="hcId.maxCostApartment" class="col-12 col-md-6 col-lg-12 col-xl-6">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-wallet2 fs-3"></i>
                </div>
                <div class="col-auto my-auto">
                    до <strong>{{ hcId.maxCostApartment.toLocaleString() }}</strong> ₽/квартира
                </div>
            </div>
        </div>
        <div v-if="hcId.minCostSquareMeter" class="col-12 col-md-6 col-lg-12 col-xl-6">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-wallet2 fs-3"></i>
                </div>
                <div class="col-auto my-auto">
                    от <strong>{{ hcId.minCostSquareMeter.toLocaleString() }}</strong> ₽/м<sup>2</sup>
                </div>
            </div>
        </div>
        <div v-if="hcId.maxCostSquareMeter" class="col-12 col-md-6 col-lg-12 col-xl-6">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-wallet2 fs-3"></i>
                </div>
                <div class="col-auto my-auto">
                    до <strong>{{ hcId.maxCostSquareMeter.toLocaleString() }}</strong> ₽/м<sup>2</sup>
                </div>
            </div>
        </div>
    </div>

    <hr v-if="hcId.layout[0] || (hcId.minCostApartment || hcId.maxCostApartment || hcId.minCostSquareMeter || hcId.maxCostSquareMeter)"
        class="pb-3">

    <div class="row">
        <div v-if="hcId.deadlineFirst" class="col-12 pb-2">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-calendar3 fs-3"></i>
                </div>
                <div class="col my-auto">
                    Срок сдачи: <strong>{{
                        hcId.deadlineFirst + (hcId.deadlineFirst === hcId.deadlineSecond ? "" : (" - " + hcId.deadlineSecond))
                    }}</strong>
                </div>
            </div>
        </div>
        <div v-if="hcId.numberFloors" class="col-12 pb-2">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-building fs-3"></i>
                </div>
                <div class="col my-auto">
                    Этажей: <strong>{{ hcId.numberFloors }}</strong>
                </div>
            </div>
        </div>
        <div v-if="hcId.classHC" class="col-12 pb-2">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-house fs-3"></i>
                </div>
                <div class="col my-auto">
                    Класс: <strong>{{
                        hcId.classHC === "ECONOMY" ? "Эконом" : hcId.classHC === "COMFORT" ? "Комфорт" : hcId.classHC === "BUSINESS" ? "Бизнес" : hcId.classHC === "PREMIUM" ? "Премиум" : ""
                    }}</strong>
                </div>
            </div>
        </div>
        <div v-if="hcId.material.length > 0" class="col-12 pb-2">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-bricks fs-3"></i>
                </div>
                <div class="col my-auto">
                    Материал стен: <strong v-for="item in hcId.material">
                    {{
                        item === "MONOLITH" ? "Монолитный, " : item === "MONOLITH_BRICK" ? "Монолитно-кирпичный, " : item === "BRICK" ? "Кирпичный, " : item === "PANEL" ? "Панельный, " : item === "BLOCK" ? "Блочный, " : "Смешаный, "
                    }}
                </strong>
                </div>
            </div>
        </div>
        <div v-if="hcId.finishType.length > 0" class="col-12 pb-2">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-hammer fs-3"></i>
                </div>
                <div class="col my-auto">
                    Отделка: <strong v-for="item in hcId.finishType">
                    {{
                        item === "FINISHING" ? "Чистовая, " : item === "FINISHING_FURNITURE" ? "Чистовая с мебелью, " : item === "WITHOUT_FINISHING" ? "Без отделки, " : item === "PRE_FINISHING" ? "Предчистовая, " : item === "DRAFT" ? "Черновая, " : "Отсутствует, "
                    }}
                </strong>
                </div>
            </div>
        </div>
        <div v-if="hcId.region || hcId.address" class="col-12 pb-2">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-map fs-3"></i>
                </div>
                <div class="col my-auto">
                    Адрес: <strong>{{
                        (hcId.region ? hcId.region + ", " : "") +
                        (hcId.address ? hcId.address : "")
                    }}</strong>
                </div>
            </div>
        </div>
        <div v-if="hcId.metro" class="col-12 pb-2">
            <div class="row">
                <div class="col-auto my-auto">
                    <i class="bi bi-train-front fs-3"></i>
                </div>
                <div class="col my-auto">
                    Метро: <strong>{{ hcId.metro }}</strong>
                </div>
            </div>
        </div>
    </div>

    <p v-if="hcId.description" class="pt-3">{{ hcId.description }}</p>

    <div class="row">
        <hc-developer-card v-for="item in hcId.developers" :developer="item" class="col"/>
    </div>

</template>

<script>
import {useRoute} from "vue-router";
import useHc from "@/hooks/useHc";
import {onMounted} from "vue";
import HcDeveloperCard from "@/components/pages/hc/HcDeveloperCard";

export default {
    name: "housing-complex",
    components: {HcDeveloperCard},
    setup() {
        const route = useRoute()
        const id = route.params.id

        const {getHcId, hcId} = useHc()
        onMounted(async () => {
            await getHcId(id)
        })

        return {hcId}
    }
}
</script>

<style scoped>

</style>
