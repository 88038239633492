import {ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {serverUrl} from "@/App.vue";

export default function useDeveloper() {

    const route = useRoute()

    const developerLoader = ref(false)
    const developerException = ref("")
    const developers = ref([])
    const developer = ref({
        name: null,
        image: [],
        description: null,
        housingComplexes: []
    })

    const getHomeDevelopers = async () => {
        developerException.value = ""
        developerLoader.value = true
        await axios.get(
            serverUrl + `developers?number=3`
        )
            .then(response => {
                developers.value = response.data
            })
            .catch(async e => {
                try {
                    developerException.value = e.response.data.error
                } catch {
                    developerException.value = "Exception"
                }
            })
            .finally(() => developerLoader.value = false)
    }

    const getDevelopers = async () => {
        developerException.value = ""
        developerLoader.value = true
        await axios.get(
            serverUrl + `developers`, {params: route.query}
        )
            .then(response => {
                developers.value = response.data
            })
            .catch(async e => {
                try {
                    developerException.value = e.response.data.error
                } catch {
                    developerException.value = "Exception"
                }
            })
            .finally(() => developerLoader.value = false)
    }

    const getDeveloper = async (id) => {
        developerException.value = ""
        developerLoader.value = true
        await axios.get(
            serverUrl + `developers/` + id
        )
            .then(response => {
                developer.value = response.data
            })
            .catch(async e => {
                try {
                    developerException.value = e.response.data.error
                } catch {
                    developerException.value = "Exception"
                }
            })
            .finally(() => developerLoader.value = false)
    }

    return {getHomeDevelopers, getDevelopers, getDeveloper, developers, developer}

}
