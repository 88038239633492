export const auth = {
    state: () => ({
        name: "",
        auth: false
    }),
    getters: {},
    mutations: {
        auth(state, name) {
            state.name = name
            state.auth = true
        },
        unAuth(state) {
            state.name = ""
            state.auth = false
        }
    },
    actions: {},
    namespaced: true
}
