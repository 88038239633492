<template>
  <input :value="modelValue" @input="updateInput">
</template>

<script>
export default {
  name: "input-ple",
  props: {
    modelValue: [String, Number]
  },
  setup(_, {emit}) {
    const updateInput = (event) => {
      emit("update:modelValue", event.target.value)
    }
    return {updateInput}
  }
}
</script>

<style scoped>

</style>