<template>
  <div id="carouselExampleFade" class="carousel slide carousel-fade pb-3">
    <div class="carousel-inner">
      <div class="carousel-item ratio ratio-16x9 active">
        <img :src="article.image" class="d-block w-100 rounded object-fit-cover" :alt="article.title">
      </div>
    </div>
  </div>
  <h3 class="pb-3">{{ article.title }}</h3>
  <p v-html="text"></p>
</template>

<script>
import {useRoute} from "vue-router";
import useArticles from "@/hooks/useArticles";
import {onMounted, ref} from "vue";

export default {
  name: "article",
  setup() {
    const route = useRoute()
    const id = route.params.id
    const text = ref("")

    const {getArticle, article} = useArticles()

    onMounted(async () => {
          await getArticle(id)
          text.value = article.value.text.replace(/\n/g, "<br>");
        }
    )

    return {article, text}
  }
}
</script>

<style scoped>

</style>