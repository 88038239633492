<template>
  <developers-search
      :search="filter.search"
      :how="filter.how"
      @newSearch="updateGetDevelopers"
  />
  <div v-for="item in developers.developers">
    <developers-card
        :developer="item"
    />
  </div>
  <pagination
      :number="10"
      :page="filter.page"
      :total="developers.total"
      @newPagination="updatePage"
  />
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import DevelopersSearch from "@/components/pages/developers/DevelopersSearch";
import useDeveloper from "@/hooks/useDeveloper";
import DevelopersCard from "@/components/pages/developers/DevelopersCard";
import Pagination from "@/components/UI/Pagination";

export default {
  name: "developers",
  components: {Pagination, DevelopersCard, DevelopersSearch},
  setup() {
    const route = useRoute()
    const router = useRouter()

    const {getDevelopers, developers} = useDeveloper()

    onMounted(getDevelopers)

    const filter = ref({
      search: route.query.search ? route.query.search : "",
      how: route.query.how ? route.query.how : "",
      page: Number(route.query.page) ? Number(route.query.page) : 1
    })

    const myQuery = () => {
      return {
        search: filter.value.search,
        how: filter.value.how,
        page: filter.value.page
      }
    }

    const updatePage = async (page) => {
      filter.value.page = page
      await router.replace({
        query: myQuery()
      })
      location.reload()
    }

    const updateGetDevelopers = async (search, how) => {
      filter.value.search = search
      filter.value.how = how
      filter.value.page = 1
      await router.replace({
        query: myQuery()
      })
      location.reload()
    }

    return {updateGetDevelopers, updatePage, developers, filter}
  }
}
</script>

<style scoped>

</style>
