<template>
  <main class="">
    <div class="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/header.jpg" alt="head-cover" class="h-100 w-100"
               style="object-fit: cover; object-position: 60% 70%;">
            <div class="carousel-caption text-start">
              <div class="container">
              <h1>База данных</h1>
              <p>по жилищным комплексам России</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true"
                    aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../../../assets/img/head.jpeg" alt="head-cover" class="h-100 w-100"
                   style="object-fit: cover; object-position: 50% 38%;">
              <div class="container">
                <div class="carousel-caption text-start">
                  <h1>База данных</h1>
                  <p>по жилищным комплексам России</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img src="../../../assets/img/head.jpeg" alt="head-cover" class="h-100 w-100"
                   style="object-fit: cover; object-position: 50% 30%;">
              <div class="container">
                <div class="carousel-caption">
                  <h1>База данных</h1>
                  <p>по жилищным комплексам России</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img src="../../../assets/img/head.jpeg" alt="head-cover" class="h-100 w-100"
                   style="object-fit: cover; object-position: 50% 65%;">
              <div class="container">
                <div class="carousel-caption text-end">
                  <h1>База данных</h1>
                  <p>по жилищным комплексам России</p>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>-->
  </main>
</template>

<script>
export default {
  name: "ple-header"
}
</script>

<style scoped>

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}

.carousel {
  margin-bottom: 2rem;
}

.carousel-caption {
  top: 1.5rem;
  z-index: 10;
}

.carousel-item {
  height: 32rem;
}

.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

@media (min-width: 40em) {
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}

</style>
