<template>
  <img v-if="developer.image.length > 0" :src="'data:image;base64,' + developer.image[0].image" alt="developer"
       class="object-fit-cover rounded-circle">
  <div v-else class="parent">
    <div class="border object-fit-cover rounded-circle wh-140 text-center fs-4 text-secondary">{{ developer.name }}</div>
  </div>
  <h2 v-if="developer.name" class="fw-normal text-truncate pt-2 ">{{ developer.name }}</h2>
  <p v-if="developer.description" class="text-developer-card">{{ developer.description }}</p>
  <div v-else class="d-none d-md-block" style="height: 88px;"></div>
  <p v-if="developer.id"><a class="btn btn-secondary" :href="'/developers/' + developer.id">
    Подробнее »</a></p>
</template>

<script>
export default {
  name: "home-developer-card",
  props: {
    developer: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style scoped>
img {
  width: 140px;
  height: 140px;
}

.wh-140 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
}

.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.text-developer-card {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>