import {createStore} from 'vuex'
import {utm} from "./utm";
import {auth} from "./auth";

export default createStore({
    modules: {
        auth: auth,
        utm: utm
    }
})
