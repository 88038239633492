<template>
  <a :href="'/developers/' + developer.id"
     class="link-secondary text-decoration-none text-nowrap text-truncate">
    <img v-if="developer.image.length > 0" :src="'data:image;base64,' + developer.image[0].image" alt="developer"
         class="object-fit-cover rounded-circle">
    <span v-else>Застройщик:</span>
    <span class="ps-3">{{ developer.name }}</span></a>
</template>

<script>
export default {
  name: "hc-developer-card",
  props: {
    developer: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style scoped>
img {
  width: 75px;
  height: 75px;
}
</style>