<template>
    <div id="carouselExampleFade" class="carousel slide carousel-fade pb-3">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img :src="article.image" class="d-block w-100 rounded object-fit-contain" :alt="article.title">
            </div>
        </div>
    </div>
    <h3 class="pb-3">{{ article.title }}</h3>
    <p v-html="text"></p>
</template>

<script>
import {useRoute} from "vue-router";
import useArticles from "@/hooks/useArticles";
import {onMounted} from "vue";
import {ref} from "vue";

export default {
    name: "news",
    setup() {
        const route = useRoute()
        const id = route.params.id
        const text = ref("")

        const {getNew, article} = useArticles()

        onMounted(async () => {
                await getNew(id)
                text.value = article.value.text.replace(/\n/g, "<br>");
            }
        )

        return {article, text}
    }
}
</script>

<style scoped>

</style>