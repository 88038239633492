<template>
  <form class="form-login">
    <h1>
      <strong>Error 404 NotFound</strong>: Стрнаница не найдена
    </h1>
    <p>
      <a @click="$router.push('/')">Вернуться на глувную</a>
    </p>

  </form><!-- .form-login -->
</template>

<script>
export default {
  name: "not-found"
}
</script>

<style scoped>

</style>