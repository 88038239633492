<template>
    <a class="card shadow-sm-card cursor-pointer mb-3 text-decoration-none text-dark" :href="'/object/' + object.id">
        <div class="row g-0">
            <div class="col-md-4">
                <div v-if="object.images.length > 0" class="ratio ratio-1x1">
                    <img :src="'data:image;base64,' + object.images[0].image" class="img-fluid rounded-ple object-fit-cover" alt="Объявление">
                </div>
            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <h5>{{ getType(object.type) }}</h5>
                    <h6
                        class="card-title address"
                        @click.prevent="getMap()"
                    >
                        г. {{ object.localityName }}, {{ getAddress(object.address) }} <span style="font-weight: normal">(показать на карте)</span>
                    </h6>
                    <div v-if="isViewMap" class="map_here" @click.prevent="null">
                        <l-map class="block_map" :zoom="zoom" :center="[object.latitude, object.longitude]">
                            <l-tile-layer :url="url"></l-tile-layer>
                            <l-marker :lat-lng="[object.latitude, object.longitude]"></l-marker>
                        </l-map>
                    </div>
                    <p v-if="object.description" class="card-text text-hc-card">{{ sliceDescription(object.description) }}</p>
                    <div class="row card-text">
                        <div v-if="object.area" class="col-6 pb-2 small">
                            <i class="bi bi-rulers"></i> {{ object.area }} {{ getAreaUnitValue(object.areaUnit) }}
                        </div>

                        <div v-if="object.floor" class="col-6 pb-2 small">
                            <i class="bi bi-building"></i> {{ object.floor }} этаж из {{ object.floorsTotal }}
                        </div>

                        <div v-if="object.price" class="col-6 pb-2 small">
                            <i class="bi bi-wallet2 "></i> {{ formatPrice(object.price) }} ₽
                        </div>

                        <div v-if="object.rooms" class="col-6 pb-2 small">
                            <i class="bi bi-columns "></i> {{ object.rooms }}-х комнатная
                        </div>
                    </div>

                    <div v-for="metroItem in object.metro">
                        <p v-if="object.metro" class="card-text small text-muted">До метро {{ metroItem.name }} {{ metroItem.timeOnTransport }} мин.</p>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import {ref} from "vue";
import object from "../../../views/Object.vue";

export default {
    name: "objectCard",
    computed: {
        object() {
            return object
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    data: function () {
        return {
            isViewMap: false
        }
    },
    props: {
        object: {
            type: Object,
            default: {}
        }
    },
    setup() {
        const zoom = ref(15);
        const url = ref('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');

        return {
            zoom,
            url,
        }
    },
    methods: {
        formatPrice(num){
            if (num < 1000000) {
                return (num / 1000).toFixed(1) + " тыс.";
            } else {
                return (num / 1000000).toFixed(1) + " млн.";
            }
        },
        getType(type) {
            switch (type) {
                case "SALE":
                case "продажа":
                    return "Продажа";
                default:
                case "RENT":
                case "аренда":
                    return "Аренда";
            }
        },
        sliceDescription(text) {
            const length_text = 200;
            text = text.replace(/&quot;/g, '"');

            if (!text.length <= length_text) {
                return text.slice(0, length_text) + "...";
            }

            return text;
        },
        getAddress(text) {
            return text;
        },
        getMap() {
            this.isViewMap = !this.isViewMap;
        },
        getAreaUnitValue(areaUnit) {
            switch (areaUnit) {
                // case "кв.м":
                //     return "м<sup>2</sup>";
                default:
                    return areaUnit;
            }
        }
    }
}
</script>


<style scoped>
    .shadow-sm-card {
        transition: all .3s;
    }

    .shadow-sm-card:hover {
        box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .125) !important
    }

    .address:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .leaflet-container {
        height: 300px !important;
    }
</style>