<template>
    <div class="pb-4">
        <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button bg-white text-dark" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne">
                        Подобрать объявление
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                     aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                        <form class="row">
                            <div class="col-12 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMetro" placeholder="Метро" maxlength="255">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMinCost" placeholder="Мин. ₽">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMaxCost" placeholder="Макс. ₽">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMinSquareMeter" placeholder="Мин. м2">
                            </div>
                            <div class="col-sm-3 pb-3">
                                <input type="text" class="form-control" v-model="data.searchMaxSquareMeter" placeholder="Макс. м2">
                            </div>
                            <div class="col-sm-6 pb-3">
                                <select class="form-select" v-model="data.searchType">
                                    <option value="">Тип объявления</option>
                                    <option value="sale">Продажа</option>
                                    <option value="rent">Аренда</option>
                                </select>
                            </div>
                            <div class="col-sm-6 pb-3">
                                <select class="form-select" v-model="data.searchCategoryObject">
                                    <option value="">Тип объекта</option>
                                    <option value="garage">Гараж</option>
                                    <option value="dacha">Дача</option>
                                    <option value="house">Дом</option>
                                    <option value="apartment">Квартира</option>
                                    <option value="commercial">Коммерческая</option>
                                    <option value="room">Комната</option>
                                    <option value="area">Участок</option>
                                </select>
                            </div>
                            <div class="pb-3">
                                <div class="card card-body border-0 rounded-0 py-0">
                                    <div class="row small">
                                        <div class="col-auto ps-0 pe-1">
                                            Сортировать:
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="default" value="" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="default">
                                                по умолчанию
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="aPrice" value="APRICE" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="aPrice">
                                                по цене <i class="bi bi-sort-down-alt"></i>
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="dPrice" value="DPRICE" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="dPrice">
                                                по цене <i class="bi bi-sort-down"></i>
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="aMeter" value="AMETER" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="aMeter">
                                                по площади <i class="bi bi-sort-down-alt"></i>
                                            </label>
                                        </div>
                                        <div class="col-auto px-1">
                                            <input type="radio" class="btn-check" id="dMeter" value="DMETER" v-model="data.searchHow" @change="newSearch">
                                            <label class="link-primary" for="dMeter">
                                                по площади <i class="bi bi-sort-down"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col"></div>
                            <div class="col-auto">
                                <button class="btn btn-secondary" type="button" @click="newSearch">Подобрать</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {reactive} from "vue";

export default {
    name: "object-search",
    props: {
        metro: {
            type: String,
            default: ""
        },
        how: {
            type: String,
            default: ""
        },
        minCost: {
            type: String,
            default: ""
        },
        maxCost: {
            type: String,
            default: ""
        },
        minSquareMeter: {
            type: String,
            default: ""
        },
        maxSquareMeter: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: ""
        },
        categoryObject: {
            type: String,
            default: ""
        },
    },
    setup(props, {emit}) {
        const data = reactive({
            searchMetro: props.metro,
            searchHow: props.how,
            searchMinCost: props.minCost,
            searchMaxCost: props.maxCost,
            searchMinSquareMeter: props.minSquareMeter,
            searchMaxSquareMeter: props.maxSquareMeter,
            searchType: props.type,
            searchCategoryObject: props.categoryObject,
        });

        function newSearch() {
            emit(
                'newSearch',
                data.searchMetro,
                data.searchHow,
                data.searchMinCost,
                data.searchMaxCost,
                data.searchMinSquareMeter,
                data.searchMaxSquareMeter,
                data.searchType,
                data.searchCategoryObject
            )
        }

        return {newSearch, data}
    }
}
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon) !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill=''%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

input:checked + label {
    color: var(--bs-gray-700) !important;
    cursor: default !important;
    font-weight: 600;
}

input:hover + label {
    color: var(--bs-gray-700) !important;
}

input + label {
    color: var(--bs-gray-600) !important;
    cursor: pointer !important;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: var(--bs-gray-700);
    border-color: var(--bs-gray-700);
}
.form-check-input[type=checkbox]:indeterminate {
    background-color: var(--bs-gray-700);
    border-color: var(--bs-gray-700);
}
</style>