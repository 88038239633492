<template>
    <objects-search
        :metro="filter.metro"
        :how="filter.how"
        :minCost="filter.minCost"
        :maxCost="filter.maxCost"
        :minSquareMeter="filter.minSquareMeter"
        :maxSquareMeter="filter.maxSquareMeter"
        :available="filter.available"
        :type="filter.type"
        :categoryObject="filter.categoryObject"
        @newSearch="updateGetObjects"
    />

    <div v-for="item in object.objects">
        <object-card :object="item"/>
    </div>

    <pagination
        :number="10"
        :page="filter.page"
        :total="object.total"
        @newPagination="updatePage"
    />
</template>

<script>
import Pagination from "@/components/UI/Pagination.vue";
import ObjectsSearch from "@/components/pages/objects/ObjectsSearch.vue";
import ObjectCard from "@/components/pages/objects/ObjectCard.vue";
import hc_card from "@/components/pages/hc/HcCard.vue";
import {useRoute, useRouter} from "vue-router";
import useObjects from "@/hooks/useObjects";
import {onMounted, ref} from "vue";
import HcSearch from "@/components/pages/hc/HcSearch.vue";

export default {
    name: "objects",
    components: {HcSearch, hc_card, Pagination, ObjectsSearch, ObjectCard},
    setup() {
        const route = useRoute()
        const router = useRouter()

        const {getObjects, object} = useObjects()

        onMounted(async () => {
            await router.replace({
                query: myQuery()
            })
            await getObjects()
        })

        const filter = ref({
            region: route.query.region ?? "",
            metro: route.query.metro ?? "",
            how: route.query.how ?? "",
            minCost: route.query.minCost ?? "",
            maxCost: route.query.maxCost ?? "",
            minSquareMeter: route.query.minSquareMeter ?? "",
            maxSquareMeter: route.query.maxSquareMeter ?? "",
            page: Number(route.query.page) ? Number(route.query.page) : 1,
            type: route.query.type ?? "",
            categoryObject: route.query.categoryObject ?? "",
        })

        const myQuery = () => {
            return {
                metro: filter.value.metro,
                how: filter.value.how,
                minCost: filter.value.minCost,
                maxCost: filter.value.maxCost,
                minSquareMeter: filter.value.minSquareMeter,
                maxSquareMeter: filter.value.maxSquareMeter,
                page: filter.value.page,
                type: filter.value.type,
                categoryObject: filter.value.categoryObject,
            }
        }

        const updatePage = async (page) => {
            filter.value.page = page
            await router.replace({
                query: myQuery()
            })
            location.reload()
        }

        const updateGetObjects = async (
            metro,
            how,
            minCost,
            maxCost,
            minSquareMeter,
            maxSquareMeter,
            type,
            categoryObject
        ) => {
            filter.value.metro = metro
            filter.value.how = how
            filter.value.minCost = minCost
            filter.value.maxCost = maxCost
            filter.value.minSquareMeter = minSquareMeter
            filter.value.maxSquareMeter = maxSquareMeter
            filter.value.page = 1
            filter.value.type = type
            filter.value.categoryObject = categoryObject
            await router.replace({
                query: myQuery()
            })

            location.reload()
        }

        return {updateGetObjects, updatePage, object, filter}
    }
}
</script>
<style scoped>

</style>