<template>

  <div v-if="!honest" class="row featurette">
    <div class="col-sm-7 my-auto">
      <h3 v-if="articles.title" class="featurette-heading fw-normal heading-featurette-card">{{ articles.title }}</h3>
      <p v-if="articles.text" class="text-featurette-card">{{ articles.text }}</p>
      <p v-if="articles.id"><a class="btn btn-secondary" :href="'/articles/' + articles.id">Читать дальше...</a></p>
    </div>
    <div v-if="articles.image" class="col-sm-5">
      <div class="ratio ratio-1x1">
        <img :src="articles.image" alt="featurette"
             class="ratio ratio-1x1 object-fit-contain rounded">
      </div>
    </div>
  </div>

  <div v-else class="row featurette">
    <div class="col-sm-7 order-sm-2 my-auto">
      <h3 v-if="articles.title" class="featurette-heading fw-normal heading-featurette-card">{{ articles.title }}</h3>
      <p v-if="articles.text" class="text-featurette-card">{{ articles.text }}</p>
      <p v-if="articles.id"><a class="btn btn-secondary" :href="'/articles/' + articles.id">Читать дальше...</a></p>
    </div>
    <div v-if="articles.image" class="col-sm-5 order-sm-1">
      <div class="ratio ratio-1x1">
        <img :src="articles.image" alt="featurette"
             class="ratio ratio-1x1 object-fit-contain rounded">
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "home-articles-card",
  props: {
    articles: {
      type: Object,
      default: {}
    },
    honest: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.heading-featurette-card {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-featurette-card {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.featurette-heading {
  letter-spacing: -.05rem;
}

@media (min-width: 40em) {
  .featurette-heading {
    /*font-size: 50px;*/
  }
}
</style>