<template>

  <div class="row">
    <!--suppress JSUnresolvedVariable -->
    <div v-for="item in hc.housingComplexes" class="col-md-4 col-sm-6 col-12">
      <home_hc_card :hc="item"/>
    </div>
  </div>

</template>

<script>
import useHc from "@/hooks/useHc";
import home_hc_card from "@/components/pages/home/HomeHcCard";
import {onMounted} from "vue";

export default {
  name: "home-hc",
  components: {home_hc_card},
  setup() {
    const {getHomeHc, hc} = useHc()

    onMounted(getHomeHc)

    return {hc}
  }
}
</script>

<style scoped>

</style>