import {ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {serverUrl} from "@/App.vue";

export default function useHc() {

    const route = useRoute()
    const selectedCity = localStorage.getItem('selectedCity');

    const hcLoader = ref(false)
    const hcException = ref("")
    const hc = ref([])
    const hcId = ref({
        images: [],
        name: null,
        layout: [],
        numberRooms: null,
        minimumArea: null,
        minimumCost: null,
        minCostApartment: null,
        maxCostApartment: null,
        minCostSquareMeter: null,
        maxCostSquareMeter: null,
        deadlineFirst: null,
        deadlineSecond: null,
        numberFloors: null,
        classHC: null,
        material: [],
        finishType: [],
        region: null,
        address: null,
        metro: null,
        developers: []
    })

    const getHomeHc = async () => {
        hcException.value = ""
        hcLoader.value = true

        await axios.get(
            serverUrl + `hc?number=6&selectedCity=${selectedCity}`
        )
            .then(response => {
                hc.value = response.data
            })
            .catch(async e => {
                try {
                    hcException.value = e.response.data.error
                } catch {
                    hcException.value = "Exception"
                }
            })
            .finally(() => hcLoader.value = false)
    }

    const getHc = async () => {
        hcException.value = ""
        hcLoader.value = true

        const params = {
            ...route.query, // если вы хотите сохранить существующие параметры
            selectedCity: selectedCity // добавляем значение из localStorage
        };

        await axios.get(
            serverUrl + `hc`, {params}
        )
            .then(response => {
                hc.value = response.data
            })
            .catch(async e => {
                try {
                    hcException.value = e.response.data.error
                } catch {
                    hcException.value = "Exception"
                }
            })
            .finally(() => hcLoader.value = false)
    }

    const getHcId = async (id) => {
        hcException.value = ""
        hcLoader.value = true
        await axios.get(
            serverUrl + `hc/` + id
        )
            .then(response => {
                hcId.value = response.data
            })
            .catch(async e => {
                try {
                    hcException.value = e.response.data.error
                } catch {
                    hcException.value = "Exception"
                }
            })
            .finally(() => hcLoader.value = false)
    }

    return {getHomeHc, getHc, getHcId, hc, hcId}

}
