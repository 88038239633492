<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a class="link-secondary" @click="$router.push('/admin')">
                    Панель администратора
                </a>
            </li>
            <li class="breadcrumb-item">
                <a v-if="type === 'news'" class="link-secondary" @click="$router.push('/admin/news')">
                    Новости
                </a>
                <a v-else-if="type === 'articles'" class="link-secondary" @click="$router.push('/admin/articles')">
                    Статьи
                </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Запись</li>
        </ol>
    </nav>

    <form>
        <div class="pb-3">
            <select class="form-select" v-model="newType">
                <option value="news">Новость</option>
                <option value="articles">Статья</option>
            </select>
        </div>
        <div class="pb-3">
            <input type="text"
                   class="form-control"
                   maxlength="255"
                   placeholder="Введите заголовок"
                   v-model="article.title"
            >
        </div>
        <div class="pb-3">
      <textarea class="form-control"
                placeholder="Введите текст"
                rows="7"
                v-model="article.text"
                style="min-height: 5.4rem!important;"
      ></textarea>
        </div>
        <div class="pb-3">
            <input type="url"
                   class="form-control"
                   maxlength="255"
                   placeholder="Введите URL изображения"
                   v-model="article.image"
            >
        </div>
        <div v-if="id === 'add'" class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-secondary" @click="updateArticles">Сохранить</button>
        </div>
        <div v-else class="d-grid gap-2 d-md-block">
            <button type="button" class="btn btn-secondary me-md-3" @click="updateArticles">Обновить</button>
            <button type="button" class="btn btn-danger" @click="deleteArticles">Удалить</button>
        </div>
    </form>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import useArticles from "@/hooks/useArticles";
import {onMounted, ref} from "vue";

export default {
    name: "AdminArticle",
    setup() {
        const route = useRoute()
        const router = useRouter()
        const type = route.path.split('/')[2]
        const id = route.params.id

        const newType = ref(type)

        const {
            getArticle,
            getNew,
            addArticle,
            addNew,
            updateArticle,
            updateNew,
            deleteArticle,
            deleteNew,
            article
        } = useArticles()

        onMounted(async () => {
            if (id !== 'add') {
                if (type === 'news') {
                    await getNew(id)
                } else if (type === 'articles') {
                    await getArticle(id)
                } else {
                    await router.push('/admin')
                }
            }
        })

        const updateArticles = () => {
            if (id === 'add') {
                if (newType.value === 'articles') {
                    addArticle(id)
                } else if (newType.value === 'news') {
                    addNew(id)
                }
            } else {
                if (newType.value === 'articles') {
                    updateArticle(id)
                } else if (newType.value === 'news') {
                    updateNew(id)
                }
            }
        }

        const deleteArticles = () => {
            if (newType.value === 'articles') {
                deleteArticle(id)
            } else if (newType.value === 'news') {
                deleteNew(id)
            }
        }

        return {type, id, article, newType, updateArticles, deleteArticles}
    }
}
</script>

<style scoped>

</style>