<template>
    <h3 style="margin-bottom: 50px">
        <a href="/reports">
            Вернуться к выбору файлов
        </a>
    </h3>
    <h3>Отчеты:</h3>
    <div v-for="(item, index) in report" :key="index">
        <div class="block-report row">
            <div class="col-auto">
                <span>Импорт <b>{{ getCount(item.jvalue) }}</b> объявлений из файла {{ item.fileName }}.xml от <b>{{ getDate(item.dateCreate) }}</b></span>
            </div>
            <div class="col">
                <a class="link-download-report" href="#" @click="downloadXML(item.dateCreate, item.jvalue, item.fileName)">
                    Скачать
                </a>
                <a :class="{ 'last-report': index === 0 }" class="link-open-report" href="#" @click="openXML(item.dateCreate, item.jvalue)">
                    Открыть
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import useReports from "@/hooks/useReports";
import {onMounted, ref} from "vue";

export default {
    name: "ImportReportsList",
    setup() {
        const {getReportsByFile, report} = useReports()

        const url = ref(window.location.href); // Получаем текущий URL страницы

        const extractReportFileName = (url) => {
            const regex = /\/report\/(.*)/;
            const match = url.match(regex);

            const isLastReport = match[1].indexOf('last') !== -1;

            if (isLastReport) {
                setTimeout(() => {
                    downloadLastReport(); // Передаем report в функцию
                }, 300);
            }

            return match ? match[1] : '';
        };

        const downloadLastReport = () => {
            const lastReport = report.value[0];

            openXML(lastReport.dateCreate, lastReport.jvalue);
        }

        const openXML = (unixTime, jvalue) => {
            const dateReport = getDate(unixTime);
            const xmlData = generateXML(
                dateReport,
                setArrayFromJson(jvalue)
            );

            // Создаем объект Blob из строки XML
            const blob = new Blob([xmlData], { type: 'text/xml' });

            // Создаем URL для объекта Blob
            const url = URL.createObjectURL(blob);

            window.location.href = url;

            // Освобождаем память
            URL.revokeObjectURL(url);
        }

        const getDate = (unixTime) => {
            // Создаем объект Date из Unix времени (в миллисекундах)
            const date = new Date(unixTime * 1000);

            // Получаем компоненты даты
            const day = String(date.getDate()).padStart(2, '0');  // День
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц
            const year = date.getFullYear(); // Год

            // Получаем компоненты времени
            const hours = String(date.getHours()).padStart(2, '0'); // Часы
            const minutes = String(date.getMinutes()).padStart(2, '0'); // Минуты
            const seconds = String(date.getSeconds()).padStart(2, '0'); // Секунды

            // Формируем строку в формате "dd.mm.YYYY H:i:s"
            return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
        }

        const generateXML = (dateReport, jvalueArray) => {
            let xmlString = `<?xml version="1.0" encoding="UTF-8"?>
                <result>
                    <generation-date>${dateReport}</generation-date>
            `;

            jvalueArray.forEach(element => {
                xmlString += `<ad url="https://p-l-e.ru/object/` + element + `" id="` + element + `"/>`;
            });

            xmlString += `</result>`;

            return xmlString;
        }

        const setArrayFromJson = (jValue) => {
            return JSON.parse(jValue);
        }

        const fileName = extractReportFileName(url.value);

        onMounted(async () => {
            await getReportsByFile(fileName)
        })

        return {getReportsByFile, report}
    },
    methods: {
        getDate(unixTime) {
            // Создаем объект Date из Unix времени (в миллисекундах)
            const date = new Date(unixTime * 1000);

            // Получаем компоненты даты
            const day = String(date.getDate()).padStart(2, '0');  // День
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц
            const year = date.getFullYear(); // Год

            // Получаем компоненты времени
            const hours = String(date.getHours()).padStart(2, '0'); // Часы
            const minutes = String(date.getMinutes()).padStart(2, '0'); // Минуты
            const seconds = String(date.getSeconds()).padStart(2, '0'); // Секунды

            // Формируем строку в формате "dd.mm.YYYY H:i:s"
            return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
        },
        formatDate(unixTimestamp) {
            const date = new Date(unixTimestamp * 1000);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        },
        setArrayFromJson(jValue){
            return JSON.parse(jValue);
        },
        getCount(jValue) {
            const normalArray = this.setArrayFromJson(jValue);

            return normalArray.length;
        },
        generateXML(dateReport, jvalueArray) {
            let xmlString = `<?xml version="1.0" encoding="UTF-8"?>
                <result>
                    <generation-date>${dateReport}</generation-date>
            `;

            jvalueArray.forEach(element => {
                xmlString += `<ad url="https://p-l-e.ru/object/` + element + `" id="` + element + `"/>`;
            });

            xmlString += `</result>`;

            return xmlString;
        },
        openXML(unixTime, jvalue, isNewWindow = true) {
            const dateReport = this.getDate(unixTime);
            const xmlData = this.generateXML(
                dateReport,
                this.setArrayFromJson(jvalue)
            );

            // Создаем объект Blob из строки XML
            const blob = new Blob([xmlData], { type: 'text/xml' });

            // Создаем URL для объекта Blob
            const url = URL.createObjectURL(blob);

            // Открываем URL в новой вкладке
            if (isNewWindow) {
                window.open(url, '_blank');
            } else {
                window.open(url);
            }

            // Освобождаем память
            URL.revokeObjectURL(url);
        },
        downloadXML(unixTime, jvalue, fileName) {
            const dateReport = this.getDate(unixTime);
            const xmlData = this.generateXML(
                dateReport,
                this.setArrayFromJson(jvalue)
            );
            const blob = new Blob([xmlData], {type: 'text/xml'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName + '_import_report_' + this.formatDate(unixTime) +'.xml';
            link.click();

            URL.revokeObjectURL(url);
        }
    }
}
</script>

<style scoped>

</style>