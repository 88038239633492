<template>
  <div class="sticky-lg-top" style="padding-top: 4rem; margin-top: -4rem">
    <aside class="sticky-lg-top advertising-overflow-auto">
      <div class="mx-lg-0 py-3 py-lg-0">
        <slot></slot>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "advertising"
}
</script>

<style scoped>
@media (min-width: 992px) {
  .advertising-overflow-auto {
    overflow: auto !important;
    max-height: 100vh;
  }
}

@media (max-width: 991px) {
  .advertising-overflow-auto {
    overflow: auto !important;
    max-width: 100vw;
    max-height: 50vh;
  }
}

.sticky-lg-top {
  z-index: auto !important;
}
</style>