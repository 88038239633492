<template>
  <div class="marketing">
    <div class="row">
      <div v-for="item in developers.developers" class="col-md-4">
        <home_developer_card :developer="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import useDeveloper from "@/hooks/useDeveloper";
import home_developer_card from "@/components/pages/home/HomeDeveloperCard";
import {onMounted} from "vue";

export default {
  name: "home-developer",
  components: {home_developer_card},
  setup() {
    const {developers, getHomeDevelopers} = useDeveloper()

    onMounted(getHomeDevelopers)

    return {developers}
  }
}
</script>

<style scoped>
.marketing .col-md-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
</style>