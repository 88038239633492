<template>
  <div class="pb-4">
    <a class="card shadow-sm-card cursor-pointer text-decoration-none text-dark" :href="'/'+ type + '/' + articles.id">
      <div v-if="articles.image" class="ratio ratio-16x9">
        <img :src="articles.image" class="card-img-top object-fit-cover" alt="Articles">
      </div>
      <div class="card-body">
        <h5 v-if="articles.title" class="card-title text-truncate">{{ articles.title }}</h5>
        <p v-if="articles.text" class="card-text text-articles-card">{{ articles.text }}</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "articles-card",
  props: {
    articles: {
      type: Object
    },
    type: ""
  }
}
</script>

<style scoped>
.shadow-sm-card {
  transition: all .3s;
}

.shadow-sm-card:hover {
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .125) !important
}

.text-articles-card {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
