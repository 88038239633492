<template>
  <div class="pb-4">
    <div class="row">
      <div class="input-group col">
        <input type="search"
               class="form-control"
               :modelValue="search"
               placeholder="Поиск..."
               @keyup.enter="newSearch"
               maxlength="255"
        />
        <a class="input-group-text " @click="newSearch">
          <i class="bi bi-search" aria-hidden="true"></i>
        </a>

      </div>
      <a class="input-group-text bg-white border-0 col-auto px-3" data-bs-toggle="collapse"
         data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
        <div class="d-block">
          <div class="caret-up-down"><i class="bi bi-caret-up-fill caret-up-down" aria-hidden="true"></i></div>
          <div class="caret-up-down"><i class="bi bi-caret-down-fill caret-up-down" aria-hidden="true"></i></div>
        </div>
      </a>
    </div>

    <div class="collapse small" id="collapseExample">
      <div class="card card-body border-start-0 border-end-0 border-top-0 rounded-0 py-2">
        <div class="row">
          <div class="col-auto px-1">
            Сортировать:
          </div>
          <div class="col-auto px-1">
            <input type="radio" class="btn-check" id="default" value="" :modelValue="how">
            <label class="link-primary cursor-pointer" for="default">
              по умолчанию
            </label>
          </div>
          <div class="col-auto px-1">
            <input type="radio" class="btn-check" id="aName" value="ANAME" :modelValue="how">
            <label class="link-primary cursor-pointer" for="aName">
              по названию <i class="bi bi-sort-down-alt"></i>
            </label>
          </div>
          <div class="col-auto px-1">
            <input type="radio" class="btn-check" id="dName" value="DNAME" :modelValue="how">
            <label class="link-primary cursor-pointer" for="dName">
              по названию <i class="bi bi-sort-down"></i>
            </label>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "developers-search",
  props: {
    search: {
      type: String,
      default: ""
    },
    how: {
      type: String,
      default: ""
    }
  },
  watch: {
    how() {
      this.newSearch()
    }
  },
  setup(props, {emit}) {
    const newSearch = () => {
      emit('newSearch', props.search, props.how)
    }

    return {newSearch}
  }
}
</script>

<style scoped>
.caret-up-down {
  margin-top: -0.8rem !important;
  margin-bottom: -0.8rem !important;
}

input:checked + label {
  color: var(--bs-gray-700) !important;
  cursor: default !important;
}

input:hover + label {
  color: var(--bs-gray-700) !important;
}

input + label {
  color: var(--bs-gray-600) !important;
  cursor: pointer !important;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}
</style>
