<!--suppress JSUnresolvedVariable -->
<template>
    <a class="card shadow-sm-card cursor-pointer mb-3 text-decoration-none text-dark" :href="'/hc/' + hc.id">
        <div class="row g-0">
            <div class="col-md-4">
                <div v-if="hc.images.length > 0" class="ratio ratio-1x1">
                    <img :src="'data:image;base64,' + hc.images[0].image" class="img-fluid rounded-ple object-fit-cover" alt="HousingComplex">
                </div>
            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <h5 v-if="hc.name" class="card-title">ЖК «{{ hc.name }}»</h5>
                    <p v-if="hc.description" class="card-text text-hc-card">{{ hc.description }}</p>
                    <div v-if="hc.layout.length > 0" class="row card-text">
                        <div v-for="item in hc.layout.slice(0, 4)" class="col-6 pb-2 small">
                            <span v-if="item.numberRooms === 0">Студ </span>
                            <span v-else-if="item.numberRooms === 100">Своб </span>
                            <span v-else-if="item.numberRooms === 200">Мног </span>
                            <span v-else>{{ item.numberRooms }}</span> <i class="bi bi-columns"></i>
                            от {{ item.minimumCost / 1000000 }} млн. ₽
                        </div>
                    </div>
                    <div v-else class="row card-text">
                        <div v-if="hc.deadlineFirst" class="col-auto pb-2"><i class="bi bi-calendar3"></i> {{
                                hc.deadlineFirst + (hc.deadlineFirst === hc.deadlineSecond ? "" : (" - " + hc.deadlineSecond))
                            }}
                        </div>
                        <div v-if="hc.numberFloors" class="col-auto pb-2"><i class="bi bi-building"></i> {{ hc.numberFloors }}</div>
                        <div v-if="hc.classHC" class="col-auto pb-2"><i class="bi bi-house"></i> {{
                                hc.classHC === "ECONOMY" ? "Эконом" : hc.classHC === "COMFORT" ? "Комфорт" : hc.classHC === "BUSINESS" ? "Бизнес" : hc.classHC === "PREMIUM" ? "Премиум" : ""
                            }}
                        </div>
                    </div>
                    <p v-if="hc.metro" class="card-text small text-muted">Метро {{ hc.metro }}</p>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: "hc-card",
    props: {
        hc: {
            type: Object,
            default: {}
        }
    }
}
</script>

<style scoped>
.shadow-sm-card {
    transition: all .3s;
}

.shadow-sm-card:hover {
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .125) !important
}

@media (min-width: 768px) {
    .rounded-ple {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
}

@media (max-width: 767px) {
    .rounded-ple {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
}

@media (min-width: 1200px) {
    .text-hc-card {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .text-hc-card {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media (max-width: 991px) {
    .text-hc-card {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
</style>