import {ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {serverUrl} from "@/App.vue";
import router from "@/router";
import useTokens from "@/hooks/useTokens";

export default function useObjects() {
    const route = useRoute()
    const {authHeader, logout} = useTokens()
    const selectedCity = localStorage.getItem('selectedCity');

    const objectLoader = ref(false)
    const objectException = ref("")
    const object = ref([])
    const objectId = ref({
        type: null,
        category: null,
        area: null,
        renovation: null,
        quality: null,
        description: "",
        rooms: null,
        floor: null,
        count: 0,
        region: null,
        address: null,
        metro: null,
        latitude: null,
        longitude: null,
        images: []
    })

    const getObjects = async () => {
        objectException.value = ""
        objectLoader.value = true

        const params = {
            ...route.query, // если вы хотите сохранить существующие параметры
            selectedCity: selectedCity // добавляем значение из localStorage
        };

        await axios.get(
            serverUrl + `objects`, {params}
        )
            .then(response => {
                object.value = response.data
            })
            .catch(async e => {
                try {
                    objectException.value = e.response.data.error
                } catch {
                    objectException.value = "Exception"
                }
            })
            .finally(() => objectLoader.value = false)
    }

    const getObjectId = async (id) => {
        objectException.value = ""
        objectLoader.value = true
        await axios.get(
            serverUrl + `objects/` + id
        )
            .then(response => {
                objectId.value = response.data
            })
            .catch(async e => {
                try {
                    objectException.value = e.response.data.error
                } catch {
                    objectException.value = "Exception"
                }
            })
            .finally(() => objectLoader.value = false)
    }

    return {getObjects, getObjectId, object, objectId}
}