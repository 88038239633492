<template>
    <div v-if="developer.image.length > 0" class="pb-3 text-center">
        <div class="d-block d-sm-none ratio ratio-1x1 w-50 h-50 mx-auto">
            <img :src="'data:image;base64,' + developer.image[0].image" class="d-block rounded-circle object-fit-cover"
                 :alt="developer.name">
        </div>
        <div class="d-none d-sm-block ratio ratio-1x1 w-25 h-25 mx-auto">
            <img :src="'data:image;base64,' + developer.image[0].image" class="d-block rounded-circle object-fit-cover"
                 :alt="developer.name">
        </div>
    </div>
    <h3 v-if="developer.name">{{ developer.name }}</h3>
    <p v-if="developer.description">{{ developer.description }}</p>
    <h4 v-if="developer.housingComplexes.length > 0" class="pb-2">ЖК у застройщика: {{
            developer.housingComplexes.length
        }}</h4>
    <div class="row">
        <!--suppress JSUnresolvedVariable -->
        <div v-for="item in developer.housingComplexes" class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
            <developers-hc-card :housingComplexes="item"/>
        </div>
    </div>
</template>

<script>
import {useRoute} from "vue-router";
import {onMounted} from "vue";
import useDeveloper from "@/hooks/useDeveloper";
import DevelopersHcCard from "@/components/pages/developers/DevelopersHcCard";

export default {
    name: "developer",
    components: {DevelopersHcCard},
    setup() {
        const route = useRoute()
        const id = route.params.id

        const {getDeveloper, developer} = useDeveloper()

        onMounted(() => {
                getDeveloper(id)
            }
        )

        return {developer}
    }
}
</script>

<style scoped>

</style>