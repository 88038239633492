<template>
    <div v-if="objectId.images.length > 0" id="carouselObject" class="carousel slide carousel-fade pb-3">
        <div class="carousel-inner">
            <div class="carousel-item ratio ratio-16x9 active">
                <img :src="'data:image;base64,' + objectId.images[0].image" class="d-block w-100 rounded object-fit-fill"
                     :alt="objectId.name">
            </div>
            <div v-for="item in objectId.images.slice(1, objectId.images.length-1)" class="carousel-item ratio ratio-16x9">
                <img :src="'data:image;base64,' + item.image" class="d-block w-100 rounded object-fit-fill" :alt="objectId.name">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselObject" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselObject" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <h2 v-if="objectId.type && objectId.address" class="py-3">
        {{ getType(objectId.type) }} {{ getTypeObjectText(objectId) }} {{ objectId.area }} {{ getUnit(objectId.areaUnit) }} по цене {{ objectId.price.toLocaleString() }}₽
    </h2>

    <div class="row pb-3">
        <div v-if="objectId.area" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-rulers fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Площадь <b>{{ objectId.area }} м<sup>2</sup></b>
                </span>
            </div>
        </div>

        <div v-if="objectId.renovation" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-hammer fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Ремонт <b>{{ getRenovation(objectId.renovation) }}</b>
                </span>
            </div>
        </div>

        <div v-if="objectId.rooms" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-columns  fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <b>{{ objectId.rooms }}</b>-х комнатная
                </span>
            </div>
        </div>

        <div v-if="objectId.floor" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-building fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <b>{{ objectId.floor }}</b> этаж из <b>{{ objectId.floorsTotal }}</b>
                </span>
            </div>
        </div>

        <div v-if="objectId.price" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-wallet2 fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <b>{{ objectId.price.toLocaleString() }}</b> ₽
                </span>
            </div>
        </div>

        <div v-if="objectId.mortgage" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-wallet2 fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Ипотека: <b>{{ getMortgage(objectId.mortgage) }}</b>
                </span>
            </div>
        </div>

        <div v-if="objectId.address || objectId.region" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-map fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    Адрес: <b>г. {{ objectId.region }}, {{ objectId.address }}</b>
                </span>
            </div>
        </div>

        <div v-if="objectId.metro && Array.isArray(objectId.metro) && objectId.metro.length > 0" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-train-front fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <div v-for="metroItem in objectId.metro">
                    <span>
                        Метро: <b>{{ metroItem.name }} - {{ metroItem.timeOnTransport }} мин</b>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <hr>

    <div class="block_map" v-if="objectId.longitude && objectId.latitude">
        <l-map  :zoom="zoom" :center="[objectId.latitude, objectId.longitude]">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker :lat-lng="[objectId.latitude, objectId.longitude]"></l-marker>
        </l-map>

    </div>

    <hr>
    <div class="agent_block">
        <h5>
            Информация о продавце
        </h5>

        <div v-if="objectId.agentName" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-people fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <b>{{ objectId.agentName }}</b>
                </span>
            </div>
        </div>

        <div v-if="objectId.agentPhone" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-phone fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <a :href="'tel:' + objectId.agentPhone" >
                        <b>{{ objectId.agentPhone }}</b>
                    </a>
                </span>
            </div>
        </div>

        <div v-if="objectId.agentEmail" class="row">
            <div class="col-auto my-auto">
                <i class="bi bi-envelope-fill fs-3"></i>
            </div>
            <div class="col-auto my-auto">
                <span>
                    <a :href="'mailto:' + objectId.agentEmail">
                        <b>{{ objectId.agentEmail }}</b>
                    </a>
                </span>
            </div>
        </div>
    </div>

    <hr>

    <p v-if="objectId.description" class="pt-3">{{ replaceDescription(objectId.description) }}</p>
</template>

<script>
import {useRoute} from "vue-router";
import useObjects from "@/hooks/useObjects";
import {onMounted, ref} from "vue";
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';

export default {
    name: "object",
    data: function () {
        return {
            isAutoClick: true
        }
    },
    setup() {
        const route = useRoute()
        const id = route.params.id
        const zoom = ref(15);
        const url = ref('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');

        const {getObjectId, objectId} = useObjects()
        onMounted(async () => {
            await getObjectId(id)
        })

        return {objectId, zoom, url}
    },
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    methods: {
        replaceDescription(text) {
            return text.replace(/&quot;/g, '"');
        },
        getTypeObjectText(object) {
            const category = object.category;

            console.log(object);

            switch (category) {
                case 'гараж':
                    return 'гаража';
                case 'дача':
                    return 'дачи';
                case 'дом':
                    return 'дома';
                case 'квартира':
                    return object.rooms + '-комнатной квартиры';
                case 'коммерческая':
                    return 'коммерческой недвижимости';
                case 'комната':
                    return 'комнаты';
                case 'участок':
                    return 'участка';
                default:
                    return category;
            }
        },
        getUnit(unit) {
            return unit ? unit : 'кв. м.';
        },
        getType(type) {
            switch (type) {
                case "SALE":
                case "продажа":
                    return "Продажа";
                default:
                case "RENT":
                case "аренда":
                    return "Аренда";
            }
        },
        getRenovation(text) {
            switch (text) {
                case "EUROPEAN_REPAIR":
                    return "Евроремонт";
                case "COSMETIC":
                    return "Косметический";
                case "DESIGNER":
                    return "Дизайнерский";
                default:
                case "REQUIRES_REPAIR":
                    return "Чистовой";
            }
        },
        getMortgage(text) {
            switch (text) {
                case '1':
                    return 'есть';
                default:
                    return 'отсутствует';
            }
        }
    },
}
</script>


<style scoped>
.carousel-control-prev-icon, .carousel-control-next-icon {
    filter: invert(100%);
}

.block_map {
    height: 400px !important;
}
</style>