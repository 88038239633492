<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a class="link-secondary" @click="$router.push('/admin')">
          Панель администратора
        </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Новости</li>
    </ol>
  </nav>

  <div class="d-grid gap-2 d-md-block mb-3">
    <button type="button" class="btn btn-secondary" @click="$router.push('/admin/news/add')">Добавить новость</button>
  </div>

  <ul class="list-group list-group-flush">
    <li v-for="item in articles.articles" class="list-group-item">
      <a class="link-secondary text-decoration-none" @click="$router.push('/admin/news/' + item.id)">
        {{ item.title }}
      </a>
    </li>
  </ul>

  <pagination
      class="mt-3"
      :number="25"
      :page="page"
      :total="articles.total"
      @newPagination="updatePage"
  />
</template>

<script>
import useArticles from "@/hooks/useArticles";
import useTokens from "@/hooks/useTokens";
import {onMounted, ref} from "vue";
import Pagination from "@/components/UI/Pagination";

export default {
  name: "admin-news",
  components: {Pagination},
  setup() {
    const {getAdminNews, articles} = useArticles()
    const {checkToken} = useTokens()
    const page = ref(1)

    onMounted(async () => {
      await checkToken()
      await getAdminNews(1)
    })

    const updatePage = (pageNumber) => {
      page.value = pageNumber
      getAdminNews(pageNumber)
    }

    return {articles, page, updatePage}
  }
}
</script>

<style scoped>

</style>