// noinspection JSCheckFunctionSignatures

import {useCookies} from "vue3-cookies";
import {ref} from "vue";
import router from "@/router";
import axios from "axios";
import {serverUrl} from "@/App.vue";

export default function useTokens() {
    const adminLoader = ref(false)
    const adminException = ref("")

    /*    const tokens = () => {
            return
        }*/

    const authHeader = async () => {
        if (useCookies().cookies.get("token")) {
            return {Authorization: 'Bearer ' + useCookies().cookies.get("token")}
        } else {
            await router.push("/login")
        }
    }

    const getToken = async (entry) => {
        adminException.value = ""
        adminLoader.value = true
        useCookies().cookies.remove("token", "/")
        await axios.post(serverUrl + `admin`, entry)
            .then(response => {
                console.log(response.data)
                login(response.data)
            })
            .catch(async e => {
                try {
                    adminException.value = e.response.data.error
                } catch {
                    adminException.value = "Exception"
                }
            })
            .finally(() => adminLoader.value = false)
    }

    const checkToken = async () => {
        adminException.value = ""
        adminLoader.value = true
        const header = await authHeader()
        await axios.get(serverUrl + `admin`, {headers: header})
            .then()
            .catch(async e => {
                if (e.response.status === 401) {
                    await logout()
                } else try {
                    adminException.value = e.response.data.error
                } catch {
                    adminException.value = "Exception"
                }
            })
            .finally(() => adminLoader.value = false)
    }

    const login = async (token) => {
        await useCookies().cookies.set("token", token, 0, "/")
        await router.push("/admin")
    }

    const logout = async () => {
        useCookies().cookies.remove("token", "/")
        await router.push("/login")
    }

    return {authHeader, getToken, checkToken, logout}
}
