<template>
    <form class="mx-auto my-5 py-5">
        <div class="pb-3">
            <input type="text"
                   placeholder="Введите логин"
                   class="form-control"
                   v-model="entry.login"
                   maxlength="255"
                   required>
        </div>
        <div class="pb-3">
            <input type="password"
                   placeholder="Введите пароль"
                   class="form-control"
                   v-model="entry.password"
                   maxlength="255"
                   required>
        </div>
        <div class="d-grid gap-2">
            <button type="button" class="btn btn-secondary" @click="login">Вход</button>
        </div>
    </form>
</template>

<script>
import {onMounted, ref} from "vue";
import useTokens from "@/hooks/useTokens";

export default {
    name: "login",
    setup() {
        const entry = ref({
            login: "",
            password: ""
        })

        const {getToken, logout} = useTokens()

        onMounted(logout)

        const login = () => {
            getToken(entry.value)
        }

        return {entry, login}
    }
}
</script>

<style scoped>
form {
    max-width: 300px;
}
</style>