<template>
    <navbar/>
    <main class="flex-grow-1">
        <PleHeader v-if="$route.path === '/'"/>
        <div class="container-xxl bd-gutter mt-3 my-md-4 bd-layout">
            <div class="row">
                <div class="col-12 col-lg-2 advertising-h-50">
                    <advertising-left/>
                </div>
                <div class="col-12 col-lg-8">
                    <router-view/>
                </div>
                <div class="col-12 col-lg-2 advertising-h-50">
                    <advertising-right/>
                </div>
            </div>
        </div>
    </main>
    <PleFooter/>
</template>

<style>
@import "assets/css/template.css";
/*noinspection CssUnknownTarget*/
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css);

@media (max-width: 991px) {
    .advertising-h-50 {
        max-height: 50vh;
    }
}
</style>
<script>
import Navbar from "@/components/Navbar";
import Advertising from "@/components/advertising/Advertising";
import PleFooter from "@/components/PLEFooter";
import PleHeader from "@/components/pages/home/PLEHeader";
import {onMounted} from "vue";
import AdvertisingLeft from "@/components/advertising/AdvertisingLeft";
import AdvertisingRight from "@/components/advertising/AdvertisingRight";

export default {
    components: {AdvertisingRight, AdvertisingLeft, PleHeader, PleFooter, Advertising, Navbar},
    setup() {
        onMounted(() => {
            window.yaContextCb = window.yaContextCb || []
            const script = document.createElement('script');
            script.src = 'https://yandex.ru/ads/system/context.js';
            script.async = true;
            document.head.appendChild(script);
        })
    }
}

//export const serverUrl = 'http://127.0.0.1:8080/';
export const serverUrl = 'https://api.p-l-e.ru/';
</script>
