<template>
  <a class="card shadow-sm-card cursor-pointer mb-3 text-decoration-none text-dark" :href="'/developers/' + developer.id">
    <div class="row g-0">
      <div :class="developer.image.length > 0 ? 'col-md-9' : ''">
        <div class="card-body">
          <h5 v-if="developer.name" class="card-title text-truncate">{{ developer.name }}</h5>
          <p v-if="developer.description" class="card-text text-hc-card">{{ developer.description }}</p>
        </div>
      </div>
      <div v-if="developer.image.length > 0" class="col-md-3 p-3">
        <div class="ratio ratio-1x1">
          <img :src="'data:image;base64,' + developer.image[0].image" class="img-fluid object-fit-cover rounded-circle" alt="HousingComplex">
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "developers-card",
  props: {
    developer: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style scoped>
.shadow-sm-card {
  transition: all .3s;
}

.shadow-sm-card:hover {
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .125) !important
}

@media (min-width: 1200px) {
  .text-hc-card {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .text-hc-card {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 991px) {
  .text-hc-card {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
</style>